import { FormControl, TimeSelect } from '@/features/forms/components'
import { LinkFormItem } from '@/features/link_forms/types'
import { useTags } from '@/features/tags/queries'
import { formatDateTime, t } from '@/lib/helpers'
import {
  Button,
  Grid,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  Checkbox,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CHALLENGE_TYPES, GENRE_TYPES, COMMENT_COLORS } from '../constants'
import { ChallengeFormInput, ChallengeType, GenreType } from '../types'
import { getQRCodeCategoriesDropDown } from '@/features/qrcode_categories/queries/getQRCodeCategoryDropDown'
import { SelectOptions } from '@/components/shared/ReactSelect/types'

type Props = {
  defaultValues?: ChallengeFormInput
  onSubmitForm: (data: ChallengeFormInput) => void
  isLoading: boolean
  forms: LinkFormItem[]
}

export const ChallengeForm = ({
  defaultValues,
  onSubmitForm,
  isLoading,
  forms,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<ChallengeFormInput>({
    defaultValues: defaultValues || { only_once: false },
    resolver: yupResolver(schema),
  })
  const [qrDropDownOptions, setQrDropDownOptions] = useState<
    SelectOptions<number>
  >([])

  const fetchQRCodeCategories = async (genre: GenreType) => {
    const { data } = await getQRCodeCategoriesDropDown(genre)
    setQrDropDownOptions(data)
    setValue(
      'qr_categories_id',
      defaultValues.qr_categories_id || data[0].value,
    )
  }

  const onSubmit = (data: ChallengeFormInput) => {
    if (isSubmitting) return

    const qr_categories_id =
      typeof data.qr_categories_id === 'number'
        ? [data.qr_categories_id]
        : data.qr_categories_id

    onSubmitForm({
      ...data,
      qr_categories_id,
    })
  }

  const { data: tagsData, isSuccess: isLoadTagsSuccess } = useTags({
    params: { per: -1 },
  })

  const tags = tagsData?.data || []
  const challengeType = watch('challengeType')
  const challengeGenre = watch('genre')

  const disabledGenre = useMemo(
    () => challengeType === ChallengeType.QRCode,
    [challengeType],
  )

  useEffect(() => {
    if (challengeType === ChallengeType.SpecificQRCode) {
      fetchQRCodeCategories(challengeGenre)
    }
  }, [challengeType, challengeGenre])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(5, 1fr)', gap: '24px 16px' }}>
        <FormControl
          id="title"
          label={t('views.admin.challenges.form.title')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
          errorMsg={errors.title?.message}
        >
          <Input
            size="lg"
            placeholder={t('views.admin.challenges.form.title')}
            ref={register}
            name="title"
            tabIndex={1}
          />
        </FormControl>

        {challengeType !== ChallengeType.Link && (
          <>
            <FormControl
              id="description"
              label={t('views.admin.challenges.form.description')}
              sx={{ gridColumn: '1/-1' }}
            >
              <Textarea
                size="lg"
                name="description"
                placeholder={t(
                  'views.admin.challenges.form.description_placeholder',
                )}
                resize="none"
                sx={{ h: '96px' }}
                ref={register}
                tabIndex={2}
              />
            </FormControl>
          </>
        )}

        <FormControl
          id="challengeType"
          label={t('views.admin.challenges.form.challenge_type')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
          errorMsg={errors.challengeType?.message}
        >
          <Select size="lg" ref={register} name="challengeType" tabIndex={3}>
            {CHALLENGE_TYPES.map(({ value }) => (
              <option value={value} key={value}>
                {t(`views.admin.challenges.form.${value}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl
          id="genre"
          label={t('views.admin.challenges.form.genre')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
          errorMsg={errors.genre?.message}
        >
          <Select
            size="lg"
            ref={register}
            name="genre"
            tabIndex={3}
            disabled={disabledGenre}
          >
            {GENRE_TYPES.map(({ value }) => (
              <option value={value} key={value}>
                {t(`views.admin.challenges.form.${value}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        {challengeType === ChallengeType.SpecificQRCode &&
          qrDropDownOptions && (
            <FormControl
              id="qr_categories_id"
              label={t('views.admin.challenges.form.qr_categories')}
              isRequired
              sx={{
                fontSize: '16px',
                gridColumn: '1/-1',
              }}
              errorMsg={errors.qr_categories_id?.message}
            >
              <Select
                size="lg"
                ref={register}
                name="qr_categories_id"
                tabIndex={3}
              >
                {qrDropDownOptions.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

        {/* {challengeType === ChallengeType.SpecificQRCode &&
          qrDropDownOptions && (
            <Controller
              name="qr_categories_id"
              defaultValue={defaultValues?.qr_categories_id ?? []}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <FormControl
                    id="qr_categories_id"
                    label={t('views.admin.challenges.form.qr_categories')}
                    isRequired
                    sx={{
                      fontSize: '16px',
                      gridColumn: '1/-1',
                    }}
                    errorMsg={errors.qr_categories_id?.message}
                  >
                    <MultiSelect
                      options={qrDropDownOptions}
                      labelledBy={'Select QR Categories'}
                      value={valuesToOptions<number>(value, qrDropDownOptions)}
                      onChange={(options) => {
                        onChange(optionsToValues(options))
                      }}
                    />
                  </FormControl>
                )
              }}
            />
          )} */}

        {(challengeType === ChallengeType.Daily ||
          challengeType === ChallengeType.Post) && (
          <>
            <FormControl
              id="only_once"
              label={t('views.admin.challenges.form.only_once')}
              sx={{ gridColumn: '1/-1' }}
            >
              <Checkbox name="only_once" ref={register} colorScheme="green" />
            </FormControl>
          </>
        )}

        {(challengeType === ChallengeType.Daily ||
          challengeType === ChallengeType.Post ||
          challengeType === ChallengeType.Link) && (
          <>
            <FormControl
              id="point"
              label={t('views.admin.challenges.form.point')}
              isRequired
              sx={{ gridColumn: '1/-1' }}
            >
              <NumberInput
                size="lg"
                defaultValue={0}
                name="point"
                tabIndex={4}
                ref={register}
              >
                <NumberInputField ref={register} />
              </NumberInput>
            </FormControl>
          </>
        )}

        {(challengeType === ChallengeType.Daily ||
          challengeType === ChallengeType.Post) && (
          <>
            {isLoadTagsSuccess && (
              <FormControl
                id="tagId"
                label={t('views.admin.challenges.form.tag')}
                sx={{ gridColumn: '1/-1' }}
              >
                <Select size="lg" ref={register} name="tagId" tabIndex={5}>
                  {tags.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}

        {challengeType === ChallengeType.Link && (
          <>
            <FormControl
              id="form_id"
              label={t('views.admin.challenges.form.form')}
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.form_id?.message}
            >
              <Select size="lg" ref={register} name="form_id" tabIndex={4}>
                {[{ title: '', id: '' }, ...forms].map(({ id, title }) => (
                  <option value={id} key={id}>
                    {title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              id="rewarding_message"
              label={t('views.admin.challenges.form.rewarding_message')}
              isRequired
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.rewarding_message?.message}
            >
              <Input
                size="lg"
                placeholder={t('views.admin.challenges.form.rewarding_message')}
                ref={register}
                name="rewarding_message"
                tabIndex={5}
              />
            </FormControl>
            <FormControl
              id="url"
              label={t('views.admin.challenges.form.url')}
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.url?.message}
            >
              <Input
                size="lg"
                placeholder={t('views.admin.challenges.form.url')}
                ref={register}
                name="url"
                tabIndex={5}
              />
            </FormControl>
          </>
        )}

        {challengeType !== ChallengeType.Link &&
          challengeType !== ChallengeType.SpecificQRCode && (
            <>
              <FormControl
                id="comment1"
                label={`${t('views.admin.challenges.form.comment')} 1`}
                sx={{ gridColumn: '1/-1' }}
              >
                <Textarea
                  size="lg"
                  name="comment1"
                  resize="none"
                  sx={{ h: '96px' }}
                  ref={register}
                  placeholder={`${t('views.admin.challenges.form.comment')} 1`}
                />
              </FormControl>

              <FormControl
                id="color1"
                label={`${t('views.admin.challenges.form.color')} 1`}
                sx={{ gridColumn: '1/-1' }}
              >
                <Select
                  size="lg"
                  ref={register}
                  name="color1"
                  placeholder={`${t(
                    'views.admin.challenges.form.color_placeholder',
                  )} 1`}
                >
                  {COMMENT_COLORS.map(({ label, value }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="comment2"
                label={`${t('views.admin.challenges.form.comment')} 2`}
                sx={{ gridColumn: '1/-1' }}
              >
                <Textarea
                  size="lg"
                  name="comment2"
                  resize="none"
                  sx={{ h: '96px' }}
                  ref={register}
                  placeholder={`${t('views.admin.challenges.form.comment')} 2`}
                />
              </FormControl>

              <FormControl
                id="color1"
                label={`${t('views.admin.challenges.form.color')} 2`}
                sx={{ gridColumn: '1/-1' }}
              >
                <Select
                  size="lg"
                  ref={register}
                  name="color2"
                  placeholder={`${t(
                    'views.admin.challenges.form.color_placeholder',
                  )} 2`}
                >
                  {COMMENT_COLORS.map(({ label, value }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

        <FormControl
          id="releasedAt"
          label={t('views.admin.challenges.form.released_at')}
          isRequired
          sx={{ gridColumn: '1/4' }}
          errorMsg={errors.releasedAt?.message}
        >
          <Input
            type="date"
            name="releasedAt"
            ref={register}
            defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
            tabIndex={5}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="releasedAtHr"
          label={t('views.admin.challenges.form.time')}
          isRequired
        >
          <TimeSelect innerRef={register} name={'releasedAtHr'} tabIndex={6} />
        </FormControl>

        <FormControl id="releasedAtMin" sx={{ mt: 'auto' }}>
          <TimeSelect
            isMin={true}
            innerRef={register}
            name={'releasedAtMin'}
            tabIndex={7}
          />
        </FormControl>

        <FormControl
          id="endedAt"
          label={t('views.admin.challenges.form.ended_at')}
          isRequired
          sx={{ gridColumn: '1/4' }}
          errorMsg={errors.endedAt?.message}
        >
          <Input
            type="date"
            name="endedAt"
            ref={register}
            defaultValue={formatDateTime(
              dayjs().add(7, 'day').toString(),
              'YYYY-MM-DD',
            )}
            tabIndex={8}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="endedAtHr"
          label={t('views.admin.challenges.form.time')}
          isRequired
        >
          <TimeSelect innerRef={register} name={'endedAtHr'} tabIndex={9} />
        </FormControl>

        <FormControl id="endedAtMin" sx={{ mt: 'auto' }}>
          <TimeSelect
            isMin={true}
            innerRef={register}
            name={'endedAtMin'}
            tabIndex={10}
          />
        </FormControl>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
        tabIndex={11}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.challenges.form.create')
          : t('views.admin.challenges.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.admin.shared.required')),
  description: yup.string(),
  challengeType: yup
    .string()
    .oneOf([
      ChallengeType.Daily,
      ChallengeType.Post,
      ChallengeType.QRCode,
      ChallengeType.Link,
      ChallengeType.SpecificQRCode,
    ]),
  genre: yup.string().oneOf([GenreType.Common, GenreType.CarbonReduction]),
  point: yup.number().integer(),
  releasedAt: yup.date().required(t('views.admin.shared.required')),
  releasedAtHr: yup.number().positive().integer().min(0).max(23),
  releasedAtMin: yup.number().positive().integer().min(0).max(59),
  endedAt: yup.date().required(t('views.admin.shared.required')),
  endedAtHr: yup.number().positive().integer().min(0).max(23),
  endedAtMin: yup.number().positive().integer().min(0).max(59),
  comment1: yup.string(),
  comment2: yup.string(),
  color1: yup.string(),
  color2: yup.string(),
  form_id: yup.string(),
  rewarding_message: yup.string(),
  url: yup.string(),
  only_once: yup.bool(),
  qr_categories_id: yup
    .number()
    .positive()
    .integer()
    .when('challengeType', {
      is: ChallengeType.SpecificQRCode,
      then: yup
        .number()
        .positive()
        .integer()
        .min(1, 'qr_categories_ids is required')
        .required('qr_categories_ids is required'),
      otherwise: yup.number().positive().integer().notRequired(),
    }),
})
