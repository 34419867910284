import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { PRIZES } from '../constants'
import { PrizeFormInput } from '../types'

import { transformPrizeInput } from '../utils'

const createPrize = async (form: PrizeFormInput) => {
  const data = transformPrizeInput(form)

  const formData = new FormData()

  formData.append('title', data.title)
  formData.append('description', data.description)
  formData.append('banner', data.banner || '')
  formData.append('note', data.note)
  formData.append('cost', data.cost)
  formData.append('limit_items', data.limit_items)
  formData.append('points', data.points)
  formData.append('first_win_prob', data.first_win_prob)
  formData.append('second_win_prob', data.second_win_prob)
  formData.append('third_win_prob', data.third_win_prob)
  formData.append('fourth_win_prob', data.fourth_win_prob)
  formData.append('starts_at', data.starts_at)
  formData.append('ends_at', data.ends_at)
  formData.append('order_number', data.order_number)
  formData.append('age_restricted', data.age_restricted.toString())
  formData.append('age_restricted', data.age_restricted.toString())
  formData.append('genre', data.genre)

  await fetchAPI({
    method: 'post',
    path: '/prize_exchanges',
    data: formData,
  })
}

export const useCreatePrize = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createPrize, {
    onSuccess: async () => {
      await queryClient.refetchQueries([PRIZES])

      toast({
        description: t('views.admin.prize_exchanges.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
