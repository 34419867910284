import { get } from 'lodash'
import { fetchAPI } from '@/lib/api'

import { API_DROPDOWN_PATH } from '../constants'
import { GenreType } from '@/features/challenges/types'
import { SelectOptions } from '@/components/shared/ReactSelect/types'

type Response = {
  data: SelectOptions<number>
}

export const getQRCodeCategoriesDropDown = async (
  genre: GenreType,
): Promise<Response> => {
  const response = await fetchAPI({
    path: API_DROPDOWN_PATH,
    params: { genre },
  })

  const data = get(response, 'data.qrcode_categories')

  return { data }
}
