import React from 'react'
import { startCase } from 'lodash'
import {
  Button,
  Grid,
  Input,
  NumberInput,
  NumberInputField,
  Select,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormControl } from '@/features/forms/components'

import { UserFormInput } from '../types'
import { CityCollection } from '@/features/types'
import { useCityOptions } from '@/lib/hooks/useCityOptions'
import { OCCUPATIONS } from '../constants'
import { t } from '@/lib/helpers'

type Props = {
  defaultValues: UserFormInput
  onSubmitForm: (data: UserFormInput) => void
  cities: CityCollection
  isLoading: boolean
}

export const UserForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, cities, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<UserFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: UserFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  const cityOptions = useCityOptions(cities, { emptyFirstItem: true })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gap: '24px 16px' }}>
        <FormControl id="nickname" label={t('views.admin.users.form.nickname')}>
          <Input
            size="lg"
            placeholder={t('views.admin.users.form.nickname')}
            ref={register}
            name="nickname"
            tabIndex={1}
          />
        </FormControl>

        <FormControl id="point" label={t('views.admin.users.form.point')}>
          <NumberInput
            size="lg"
            defaultValue={0}
            name="point"
            tabIndex={2}
            ref={register}
          >
            <NumberInputField ref={register} />
          </NumberInput>
        </FormControl>

        <FormControl
          id="point"
          label={t('views.admin.users.form.carbon_reduction_point')}
        >
          <NumberInput
            size="lg"
            defaultValue={0}
            name="carbon_reduction_point"
            tabIndex={2}
            ref={register}
          >
            <NumberInputField ref={register} />
          </NumberInput>
        </FormControl>

        <FormControl
          id="occupation"
          label={t('views.admin.users.form.occupation')}
          errorMsg={errors.occupation?.message}
        >
          <Select
            size="lg"
            ref={register}
            name="occupation"
            tabIndex={3}
            sx={{ fontSize: '16px' }}
          >
            {OCCUPATIONS.map(({ label, value }) => (
              <option key={value} value={value}>
                {startCase(label)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl
          id="birthdate"
          label={t('views.admin.users.form.birthdate')}
          errorMsg={errors.birthdate?.message}
        >
          <Input
            type="date"
            name="birthdate"
            ref={register}
            tabIndex={4}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="city_id"
          label={t('views.admin.users.form.city')}
          errorMsg={errors.city_id?.message}
        >
          <Select
            size="lg"
            ref={register}
            name="city_id"
            tabIndex={5}
            sx={{ fontSize: '16px' }}
          >
            {cityOptions.map((opt) => (
              <option key={opt.label} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        isLoading={isSubmitting || isLoading}
        tabIndex={6}
      >
        {t('views.admin.users.form.update')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  nickname: yup.string(),
  point: yup.number().integer(),
  occupation: yup.string().oneOf(OCCUPATIONS.map((o) => o.value)),
  birthdate: yup.string(),
  city_id: yup.number().integer(),
})
