import { get } from 'lodash'

import { User, UserFormInput } from './types'

export const transformUserIntoForm = (data: User): UserFormInput => {
  return {
    nickname: get(data, 'nickname'),
    point: String(get(data, 'point')),
    occupation: get(data, 'occupation'),
    birthdate: get(data, 'birthdate'),
    city_id: String(get(data, 'city_id')),
    carbon_reduction_point: get(data, 'carbon_reduction_point'),
  }
}
