import { get } from 'lodash'

import { QRCodeCategory, QRCodeCategoryFormInput } from './types'

export const transformQRCodeCategoryIntoForm = (
  data: QRCodeCategory,
): QRCodeCategoryFormInput => {
  return {
    name: get(data, 'name'),
    point: get(data, 'point'),
    marker_hue: get(data, 'marker_hue'),
    url: get(data, 'url'),
    only_once: get(data, 'only_once'),
    genre: get(data, 'genre'),
  }
}
