import { HStack, useDisclosure } from '@chakra-ui/react'
import { get } from 'lodash'
import React, { Fragment, useMemo, useState } from 'react'

import { Prize } from '@/features/prize/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { OnSortFunction } from '@/components/shared/Table/types'
import { EditPrizeModal } from '@/features/prize/components'
import { useDeletePrize } from '@/features/prize/mutations'

type Props = {
  data: Prize[]
  onSort: OnSortFunction
  genres: String[]
}

export const PrizeTable = ({ data, onSort, genres }: Props) => {
  const [actionData, setActionData] = useState<Prize>()
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const { mutate: deletePrize } = useDeletePrize()

  const columns = useMemo(() => {
    return [
      {
        Header: t('views.admin.prize_exchanges.index.order_number'),
        accessor: 'order_number',
        sortKey: 'order_number',
      },
      {
        Header: t('views.admin.prize_exchanges.index.prize_title'),
        accessor: 'title',
        sortKey: 'title',
      },
      {
        Header: t('views.admin.prize_exchanges.index.genre'),
        accessor: 'genre',
        sortKey: 'genre',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return t(`views.admin.prize_exchanges.index.${value}`)
        },
      },
      {
        Header: t('views.admin.prize_exchanges.index.points_used'),
        accessor: 'points',
        sortKey: 'points',
      },
      {
        Header: t('views.admin.prize_exchanges.index.cost'),
        accessor: 'cost',
        sortKey: 'cost',
      },
      {
        Header: t('views.admin.prize_exchanges.index.created_at'),
        accessor: 'created_at',
        sortKey: 'created_at',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.prize_exchanges.index.starts_at'),
        accessor: 'starts_at',
        sortKey: 'starts_at',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.prize_exchanges.index.ends_at'),
        accessor: 'ends_at',
        sortKey: 'ends_at',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.prize_exchanges.index.age_restricted'),
        accessor: 'age_restricted',
        sortKey: 'age_restricted',
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          const textKey = value
            ? 'views.admin.prize_exchanges.index.age_restricted_yes'
            : 'views.admin.prize_exchanges.index.age_restricted_no'

          return t(textKey)
        },
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit prize'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete prize'}
                onClick={() => {
                  if (
                    confirm(
                      t('views.admin.prize_exchanges.index.confirm_delete'),
                    )
                  ) {
                    deletePrize(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable onSort={onSort} data={memoizedData} columns={columns} />

      <EditPrizeModal
        initialData={actionData}
        disclosure={disclosure}
        genres={genres}
      />
    </Fragment>
  )
}
