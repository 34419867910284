import React, { Fragment, useMemo, useState } from 'react'
import { get } from 'lodash'
import { HStack, useDisclosure } from '@chakra-ui/react'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { t } from '@/lib/helpers'
import { EditVisibleEcosModal } from '@/features/visible_ecos/components/EditVisibleEcosModal'
import { VisibleEcosResponse } from '@/features/visible_ecos/types'
import { useDeleteVisibleEcos } from '@/features/visible_ecos/mutations/deleteVisibleEcos'

type Props = {
  data: VisibleEcosResponse[]
}

export const VisibleEcosTable = ({ data }: Props) => {
  const [formData, setFormData] = useState<VisibleEcosResponse>()
  const [editMode, setEditMode] = useState(false)
  const { mutate: deleteVisibleEcos } = useDeleteVisibleEcos()
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.visible_ecos.index.form.starts_at'),
        accessor: 'starts_at',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value,
      },
      {
        Header: t('views.admin.visible_ecos.index.form.ends_at'),
        accessor: 'ends_at',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value,
      },
      {
        Header: t('views.admin.visible_ecos.index.form.hint'),
        accessor: 'hint',
        disableSortBy: true,
        Cell: (cellProps) =>
          cellProps.value ? cellProps.value.toLocaleString() : '-',
      },
      {
        Header: t('views.admin.visible_ecos.index.form.level'),
        accessor: 'level',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.visible_ecos.index.form.co2_reduction'),
        accessor: 'co2_reduction',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t(
          'views.admin.visible_ecos.index.form.co2_reduction_carbon_reduction',
        ),
        accessor: 'co2_reduction_carbon_reduction',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.visible_ecos.index.form.tree_count'),
        accessor: 'tree_count',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t(
          'views.admin.visible_ecos.index.form.tree_count_carbon_reduction',
        ),
        accessor: 'tree_count_carbon_reduction',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.visible_ecos.index.form.message'),
        accessor: 'message',
        disableSortBy: true,
        Cell: (cellProps) =>
          cellProps.value ? cellProps.value.toLocaleString() : '-',
      },
      {
        Header: t('views.admin.visible_ecos.index.action'),
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit'}
                onClick={() => {
                  setFormData(row)
                  setEditMode(true)
                  onOpen()
                }}
              />

              <TableTrashButton
                ariaLabel={'delete'}
                onClick={() => {
                  if (confirm('Confirm Delete ?')) {
                    deleteVisibleEcos(row.id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [t])

  return (
    <Fragment>
      <DataTable data={data ?? []} columns={columns} />

      {editMode ? (
        <EditVisibleEcosModal
          disclosure={disclosure}
          initialData={formData}
          isLoading={false}
        />
      ) : (
        <></>
      )}
    </Fragment>
  )
}
