import { Tag } from '@/features/tags/types'
import { LinkForm } from '../link_forms/types'
import { QRCodeCategory } from '../qrcode_categories/types'

export type Challenge = {
  id: number
  challengeType: string
  genre: string
  title: string
  description: string
  point: number
  createdAt: string
  updatedAt: string
  releasedAt: string
  endedAt: string
  tag: Tag
  comment1: string
  comment2: string
  color1: string
  color2: string
  form?: Omit<LinkForm, 'questions'>
  rewarding_message?: string
  url?: string
  qrcode_categories?: QRCodeCategory[]
}

export enum ChallengeType {
  Daily = 'daily',
  Post = 'post',
  QRCode = 'qrcode',
  Link = 'link',
  SpecificQRCode = 'specific_qrcode',
}

export enum GenreType {
  Common = 'common',
  CarbonReduction = 'carbon_reduction',
}

export type ChallengeFormInput = {
  title: string
  description?: string
  challengeType: ChallengeType
  genre: GenreType
  only_once: boolean
  point: string
  releasedAt: string
  releasedAtHr: string
  releasedAtMin: string
  endedAt: string
  endedAtHr: string
  endedAtMin: string
  tagId: number
  comment1: string
  comment2: string
  color1: string
  color2: string
  form_id: string
  rewarding_message?: string
  url?: string
  qr_categories_id?: number | number[]
}
