import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { get, startCase } from 'lodash'
import { HStack, UseDisclosureProps } from '@chakra-ui/react'

import { User } from '@/features/users/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'

import { useDeleteUser } from '@/features/users/mutations'

type Props = {
  data: User[]
  setActionData: Dispatch<SetStateAction<User>>
  disclosure: UseDisclosureProps
}

export const UserTable = ({ data, setActionData, disclosure }: Props) => {
  const { mutate: deleteUser } = useDeleteUser()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.email'),
        accessor: 'email',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.nickname'),
        accessor: 'nickname',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.face_icon'),
        accessor: 'face_icon',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.birthdate'),
        accessor: 'birthdate',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value, 'YYYY-MM-DD')
        },
      },
      {
        Header: t('views.admin.users.index.occupation'),
        accessor: 'occupation',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return startCase(value)
        },
      },
      {
        Header: t('views.admin.users.index.city'),
        accessor: 'city_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.point'),
        accessor: 'point',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.carbon_reduction_point'),
        accessor: 'carbon_reduction_point',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.users.index.registered_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.users.index.action'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit user'}
                onClick={() => {
                  setActionData(row)
                  disclosure.onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete user'}
                onClick={() => {
                  if (confirm('Confirm Delete ?')) {
                    deleteUser(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable data={memoizedData} columns={columns} />
}
