import {
  Box,
  Button,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Select,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { GENRE_TYPES } from '@/features/challenges/constants'
import { t } from '@/lib/helpers'

import type { SubmitHandler } from 'react-hook-form'
import type { FilterParams } from '@/features/filters/types'

export interface ChallengeFilterType {
  genre: FilterParams['genre']
  min?: FilterParams['min']
  max?: FilterParams['max']
}

export interface ChallengeFilterFormProps {
  onSearch?: (filter: ChallengeFilterType) => void
}

const schema = yup.object().shape({
  genre: yup
    .mixed<'all' | 'common' | 'carbon_reduction'>()
    .oneOf(['all', 'common', 'carbon_reduction']),
  min: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? undefined : value,
    )
    .integer()
    .min(0, t('views.admin.challenges.form.filter.minimum_number_error'))
    .notRequired(),
  max: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? undefined : value,
    )
    .integer()
    .moreThan(
      yup.ref('min'),
      t('views.admin.challenges.form.filter.maximum_number_error'),
    )
    .notRequired(),
})

export const ChallengeFilterForm = ({ onSearch }: ChallengeFilterFormProps) => {
  // Hook
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ChallengeFilterType>({
    defaultValues: {
      genre: 'all',
      min: undefined,
      max: undefined,
    },
    resolver: yupResolver(schema),
  })

  // Event handler
  const onSubmit: SubmitHandler<ChallengeFilterType> = (data) => {
    if (isSubmitting) return
    onSearch && onSearch(data)
  }

  return (
    <Box
      sx={{
        paddingRight: '40px',
        paddingLeft: '40px',
        marginTop: '-9px',
        marginBottom: '24px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gap={4} templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={6}>
            <FormControl>
              <FormLabel fontSize="0.875rem" fontWeight="400">
                {t('views.admin.challenges.form.filter.type_of_genre')}
              </FormLabel>
              <Controller
                control={control}
                name="genre"
                render={(field) => (
                  <Select {...field}>
                    <option value="all">
                      {t(
                        'views.admin.challenges.form.filter.all_type_of_genre',
                      )}
                    </option>
                    {GENRE_TYPES.map(({ label, value }) => (
                      <option value={value} key={value}>
                        {t(`views.admin.challenges.form.${value}`)}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel fontSize="0.875rem" fontWeight="400">
                {t('views.admin.challenges.form.filter.minimum_number')}
              </FormLabel>
              <Controller
                control={control}
                defaultValue=""
                name="min"
                render={(field) => (
                  <NumberInput min={0} {...field}>
                    <NumberInputField />
                  </NumberInput>
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel fontSize="0.875rem" fontWeight="400">
                {t('views.admin.challenges.form.filter.maximum_number')}
              </FormLabel>
              <Controller
                control={control}
                defaultValue=""
                name="max"
                render={(field) => (
                  <NumberInput min={0} {...field}>
                    <NumberInputField />
                  </NumberInput>
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel
                fontSize="0.875rem"
                fontWeight="400"
                sx={{ visibility: 'hidden' }}
              >
                {t('views.admin.challenges.form.filter.search_button_label')}
              </FormLabel>
              <Button isFullWidth type="submit">
                {t('views.admin.challenges.form.filter.search')}
              </Button>
            </FormControl>
          </GridItem>
        </Grid>
        {errors.min && (
          <Text color="red.600" fontSize="xs">
            {errors.min.message}
          </Text>
        )}
        {errors.max && (
          <Text color="red.600" fontSize="xs">
            {errors.max.message}
          </Text>
        )}
      </form>
    </Box>
  )
}
