import { isEmpty } from 'lodash'

type SelectOption<T = string> = { label: string; value: T }

export const optionsToValues = <T = string>(options: SelectOption<T>[]) => {
  if (isEmpty(options)) {
    return []
  }

  const groupIdValues = options.map((option) => option.value)

  return groupIdValues
}

export const valuesToOptions = <T extends {}>(
  values: T[],
  options: SelectOption<T>[],
) => {
  if (isEmpty(options) || isEmpty(values)) {
    return []
  }

  const fieldOptions = options.filter((option) => {
    return values.includes(option.value)
  })

  return fieldOptions
}
