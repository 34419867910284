import { formatDateTime } from '@/lib/helpers'
import { get } from 'lodash'
import {
  Challenge,
  ChallengeFormInput,
  ChallengeType,
  GenreType,
} from './types'
import { optionsToValues } from '../filters/utils'

export const transformChallengeInput = (form: ChallengeFormInput) => {
  const {
    title,
    description,
    challengeType,
    genre,
    point,
    releasedAt,
    releasedAtHr,
    releasedAtMin,
    endedAt,
    endedAtHr,
    endedAtMin,
    tagId,
    comment1,
    comment2,
    color1,
    color2,
    form_id,
    rewarding_message,
    url,
    only_once,
    qr_categories_id,
  } = form

  return {
    title,
    description,
    challenge_type: challengeType,
    genre,
    point,
    tag_id: tagId,
    released_at: `${formatDateTime(
      releasedAt,
      'YYYY-MM-DD',
    )} ${releasedAtHr}:${releasedAtMin}`,
    ended_at: `${formatDateTime(
      endedAt,
      'YYYY-MM-DD',
    )} ${endedAtHr}:${endedAtMin}`,
    comment1,
    comment2,
    ...(color1 && { color1 }),
    ...(color2 && { color2 }),
    form_id: form_id && +form_id,
    rewarding_message,
    url,
    only_once,
    qr_categories_id,
  }
}

export const transformChallengeIntoForm = (
  data: Challenge,
): ChallengeFormInput => {
  const releasedAt = get(data, 'released_at')
  const endedAt = get(data, 'ended_at')

  return {
    title: get(data, 'title'),
    description: get(data, 'description'),
    challengeType: get(data, 'challenge_type') as ChallengeType,
    genre: get(data, 'genre') as GenreType,
    point: String(get(data, 'point')),
    tagId: get(data, 'tag.id'),
    releasedAt: formatDateTime(releasedAt, 'YYYY-MM-DD'),
    releasedAtHr: formatDateTime(releasedAt, 'HH') || '0',
    releasedAtMin: formatDateTime(releasedAt, 'mm') || '0',
    endedAt: formatDateTime(endedAt, 'YYYY-MM-DD'),
    endedAtHr: formatDateTime(endedAt, 'HH') || '0',
    endedAtMin: formatDateTime(endedAt, 'mm') || '0',
    comment1: get(data, 'comment1', ''),
    comment2: get(data, 'comment2', ''),
    color1: get(data, 'color1', ''),
    color2: get(data, 'color2', ''),
    form_id: get(data, 'form.id')?.toString(),
    rewarding_message: get(data, 'rewarding_message'),
    url: get(data, 'url'),
    only_once: get(data, 'only_once'),
    qr_categories_id: optionsToValues<number>(
      get(data, 'qrcode_categories')?.map((qr) => {
        return { label: qr.name, value: qr.id }
      }),
    )[0],
  }
}

export const challengeTypeLabels = {
  [ChallengeType.Daily]: 'Daily',
  [ChallengeType.Post]: 'Post',
  [ChallengeType.QRCode]: 'QR Code',
  [ChallengeType.Link]: 'Link',
  [ChallengeType.SpecificQRCode]: 'Specific QR Code',
}

export const genreTypeLabels = {
  [GenreType.Common]: 'Common',
  [GenreType.CarbonReduction]: 'Carbon Reduction',
}
