import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Prize } from '@/features/prize/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { PrizeTable } from './PrizeTable'
import { CreatePrizeModal } from '@/features/prize/components'

import { useMeta } from '@/features/filters'
import { usePrizes } from '@/features/prize/queries'
import { t } from '@/lib/helpers'

type Props = {
  prizes: Prize[]
  meta: Meta
  genres: String[]
}

function ChallengesPage(props: Props) {
  const { prizes: initialPrizes, meta: initialMeta, genres } = props

  const disclosure = useDisclosure()

  const { meta, setMeta, setMetas } = useMeta(initialMeta)
  const { data: prizes } = usePrizes({
    initialData: { data: initialPrizes, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.prize_exchanges.index.title')}
          addons={
            <Button onClick={disclosure.onOpen} sx={{ h: '48px', w: '148px' }}>
              {t('views.admin.prize_exchanges.index.new')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <PrizeTable
            onSort={({ sortKey, sortDirection }) => {
              setMetas({
                sort_by: sortKey,
                sort_direction: sortDirection,
              })
            }}
            data={prizes.data}
            genres={genres}
          />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreatePrizeModal disclosure={disclosure} genres={genres} />
    </Fragment>
  )
}

export default withApp(ChallengesPage)
